import React, {useState, useEffect} from 'react';

import {CometChat} from "@cometchat-pro/chat"

const updateMessages = setMessages => {
    const limit = 10;
    const messagesRequest = new CometChat.MessagesRequestBuilder()
        .setLimit(limit)
        .build();

    messagesRequest.fetchPrevious().then(
        fetched => {
            console.log("Message list fetched:", fetched);
            setMessages(fetched);
        },
        error => {
            console.log("Message fetching failed with error:", error);
        }
    );
};

export function Messages() {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        console.log("executing use one time effect");
        const listenerID = "UNIQUE_LISTENER_ID";

        CometChat.addMessageListener(
            listenerID,
            new CometChat.MessageListener({
                onTextMessageReceived: textMessage => {
                    updateMessages(setMessages);
                    return;
                    console.log("Text message received successfully", textMessage);
                    setMessages([...messages, textMessage]);
                }
            })
        );
    }, [messages]);

    useEffect(() => {
        console.log("executing use many time effect");
        updateMessages(setMessages);
    }, []);

    return (
        <React.Fragment>
            <h1
                onClick={() => updateMessages(setMessages)}

            >Messages</h1>
            {
                messages.map(message => {
                    return (<p>{message.text}</p>);
                })
            }
        </React.Fragment>

    );
}
