import React, {useState} from 'react';

import { CometChat } from "@cometchat-pro/chat"

const send = (message, setMessage) => {
	if (message.length < 1) {
		return;
	}
	const receiverID = "group2";
	const receiverType = CometChat.RECEIVER_TYPE.GROUP;
	const textMessage = new CometChat.TextMessage(receiverID, message, receiverType);

	CometChat.sendMessage(textMessage).then(
		message => {
			console.log("Message sent successfully:", message);
			setMessage('');
			// Do something with message
		},
		error => {
			console.log("Message sending failed with error:", error);
			// Handle any error
		}
	);
};

export const Write = ()  => {
	const [message, setMessage] = useState('');

	return (
		<React.Fragment>
			<input
				value={message}
				onChange={e => setMessage(e.target.value)}
				onKeyUp={e => {
					if (e.keyCode === 13){
						send(message, setMessage);
					}
				}}
			/>
			<button onClick={() => send(message, setMessage)}>
				send
			</button>
		</React.Fragment>
	);
};

