import React from 'react';
import './App.css';
import {Messages} from './components/Messages';
import {Write} from './components/Write';

function App() {
    return (
        <div className="App">
            <Messages/>
            <div
                style={{
                    background: 'gray',
                    position: 'fixed',
                    width: '100%',
                    bottom: 0,
                    padding: '1em'
                }}>
                <Write/>
            </div>
        </div>
    );
}

export default App;
